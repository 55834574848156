import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { saveAs } from "file-saver";
import { colors1, colors3 } from "../../../../colors/color";
import CustomizeTootip from "../../../../components/CustomizeTootip";
import CustomizeLegend from "../../../../components/CustomizeLegend";
import Printer2 from "../../../../components/Printer2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import { CSVLink } from "react-csv";
import GraphLoader from "../../../../components/GraphLoader";

const PvsystNetEnergy = ({ pvsystContractualEnergy, loading }) => {
  const csvDownloadRef = useRef(null);
  const graphRef = useRef(null);
  const [showBarData, setShowBarData] = useState({
    pvsystEnergy: true,
    netEnergy: true,
    difference: true,
    all: true,
  });

  const minMaxRange = useMemo(() => {
    return {
      min: Math.floor(
        pvsystContractualEnergy?.reduce(
          (acc, curr, index) =>
            Math.min(curr["pvsystEnergy"], curr["netEnergy"], acc),
          Infinity
        )
      ),
      max: Math.ceil(
        pvsystContractualEnergy?.reduce(
          (acc, curr, index) =>
            Math.max(curr["pvsystEnergy"], curr["netEnergy"], acc),
          -Infinity
        )
      ),
      graphData: pvsystContractualEnergy?.reduce((acc, curr) => {
        acc.push({
          Date: curr.name,
          pvsystEnergy: curr["pvsystEnergy"],
          netEnergy: curr["netEnergy"],
          difference: curr["difference"],
        });
        return acc;
      }, []),
    };
  }, [pvsystContractualEnergy]);

  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          position: "sticky",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <h5>PVsyst Energy (KWh) vs Net Energy (KWh)</h5>
        <Printer2
          clickhandler={() => {
            if (minMaxRange.graphData?.length > 0)
              return csvDownloadRef.current.link.click();
            else return null;
          }}
          jpgDownload={() => {
            if (!graphRef.current) return null;
            setTimeout(async () => {
              document.getElementsByClassName("cpy_right")[3].style.display =
                "block";
              exportComponentAsJPEG(graphRef, {
                fileName: "graph",
              });
              document.getElementsByClassName("cpy_right")[3].style.display =
                "none";
            }, 100);
          }}
          svgDownload={async () => {
            if (!graphRef.current) return null;
            document.getElementsByClassName("cpy_right")[3].style.display =
              "block";
            const svgData = await saveToSvg(graphRef.current.container);
            document.getElementsByClassName("cpy_right")[3].style.display =
              "none";
            saveAs(svgData, "graph.svg");
          }}
        />
      </div>
      <CSVLink
        data={minMaxRange.graphData || []}
        filename="data.csv"
        className="hidden"
        target="_blank"
        ref={csvDownloadRef}
      />

      <div
        style={{
          minWidth:
            minMaxRange.graphData?.length > 0
              ? minMaxRange.graphData.length *
                  Object.keys(minMaxRange.graphData[0]).length *
                  18 +
                "px"
              : "100%",
        }}
        className="detailed-responsive-container-4"
      >
        <ResponsiveContainer>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                left: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <GraphLoader />
            </div>
          ) : minMaxRange.graphData?.length > 0 ? (
            <ComposedChart
              margin={{
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              }}
              barCategoryGap={"20%"}
              data={minMaxRange.graphData || []}
              ref={graphRef}
              maxBarSize={30}
            >
              <CartesianGrid stroke="grey" strokeWidth={0.3} />
              <XAxis
                dataKey="Date"
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
              />
              <YAxis
                fontSize={"0.8rem"}
                fontWeight={600}
                tickLine={false}
                tickMargin={5}
                tickFormatter={(v) =>
                  v >= 1000 ? parseFloat(v / 1000).toFixed(0) + "k" : v
                }
                yAxisId="left-axis"
                //  label={{ value: "Energy", angle: -90,x:20, position: "insideLeft"}}
                // label={<AxisLabel axisType='yAxis' x={-17} y={-7}>Energy</AxisLabel>}
                domain={[
                  minMaxRange.min - minMaxRange.min / 5,
                  minMaxRange.max + minMaxRange.min / 10,
                ]}
              />
              <YAxis hide={true} yAxisId="right-axis" orientation="right" />
              <Tooltip
                content={
                  <CustomizeTootip
                    active={false}
                    payload={[]}
                    label={""}
                    TooltipValues={[
                      "PVsyst Energy",
                      "Net Energy",
                      "Difference",
                    ]}
                  />
                }
              />
              <Legend
                content={
                  <CustomizeLegend
                    active={false}
                    payload={[]}
                    LegendValues={["PVsyst Energy", "Net Energy"]}
                    data={showBarData}
                    setData={setShowBarData}
                    styles={{ fontSize: "0.8rem" }}
                  />
                }
              />
              <Bar
                hide={showBarData["pvsystEnergy"] ? false : true}
                dataKey={"pvsystEnergy"}
                fill={`${colors3[0]}`}
                yAxisId="left-axis"
              />{" "}
              <Bar
                hide={showBarData["netEnergy"] ? false : true}
                dataKey={"netEnergy"}
                fill={`${colors3[1]}`}
                yAxisId="left-axis"
              />
              <Line
                hide={false}
                type="monotone"
                dataKey="difference"
                stroke={`${colors1[4]}`}
                yAxisId="right-axis"
                strokeOpacity={0}
                dot={false}
                strokeWidth={0}
                activeDot={false}
              />
            </ComposedChart>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3>Data Not Available</h3>
            </div>
          )}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PvsystNetEnergy;
