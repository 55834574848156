import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Detailed.css";

import { colors3 } from "../../../../colors/color";

import PvsystActualGTI from "./PvsystActualGTI";
import PvsystActualGHI from "./PvsystActualGHI";
import { NetNormalisedShortFall } from "./NetNormalisedShortFall";
import PvsystContractual from "./PvsystContractual";
import {
  GHIGTIAction,
  netContractualEnergyShortfallAction,
  netNormalisedEnergyShortfallAction,
  pvsystActualTempAction,
  pvsystContractualEnergyAction,
  pvsystGHINormalisedAction,
  pvsystGIINormalisedAction,
} from "../../../../actions/energyAction";
import GraphLoader from "../../../../components/GraphLoader";
import PvsystGHINormalised from "./PvsystGHINormalised";
import PvsystGTINormalised from "./PvsystGTINormalised";
import PvsystActualGHIContainer from "./PvsystActualGHIContainer";
import PvsystActualGTIContainer from "./PvsystActualGTIContainer";
import PvsystAmbientTemp from "./PvsystAmbientTemp";
import { NetPvsystEnergy } from "./NetPvsystEnergy";
import PvsystGTINormalised2 from "./PvsystGTINormalised2";
import PvsystNetEnergy from "./PvsystNetEnergy";

const Detailed = ({ selectedLebel, scbsmbDetail, databaseStr }) => {
  const {
    projectDetail,
    loading: loading5,
    error,
  } = useSelector((state) => state.projectDetail);

  const { GHIGTI = [], loading } = useSelector((state) => state.GHIGTI);
  const { pvsystActualTemp = [], loading2 } = useSelector(
    (state) => state.pvsystActualTemp
  );
  const { pvsystContractualEnergy = [], loading3 } = useSelector(
    (state) => state.pvsystContractualEnergy
  );
  const { netNormalisedEnergyShortfall = [], loading4 } = useSelector(
    (state) => state.netNormalisedEnergyShortfall
  );

  const {
    pvsystGHINormalised = [],
    pvsystGIINormalised = [],
    loading3: loading6,
    loading4: loading7,
  } = useSelector((state) => state.pvsystNormalised);

  /* ###   Initialize State to animate progress bar   ### */

  const [progressValueAnimate, setProgressValueAnimate] = useState({
    progress1: false,
    progress2: false,
    progress3: false,
    progress4: false,
    progress5: false,
    progress6: false,
    progress7: false,
    progress8: false,
    progress9: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLebel?.endDataValue) {
      dispatch(GHIGTIAction(selectedLebel, databaseStr));
      dispatch(pvsystActualTempAction(selectedLebel, databaseStr));
      dispatch(netContractualEnergyShortfallAction(selectedLebel, databaseStr));

      if (projectDetail?.plantDataAvailability?.netPVsyst) {
        dispatch(pvsystGHINormalisedAction(selectedLebel, databaseStr));
        dispatch(pvsystGIINormalisedAction(selectedLebel, databaseStr));
      }
      if (projectDetail?.plantDataAvailability?.netContractual) {
        dispatch(pvsystContractualEnergyAction(selectedLebel, databaseStr));
        dispatch(
          netNormalisedEnergyShortfallAction(selectedLebel, databaseStr)
        );
      }
    }
  }, [dispatch, selectedLebel?.endDataValue]);

  useEffect(() => {
    dispatch(GHIGTIAction({}, databaseStr));
    dispatch(pvsystActualTempAction({}, databaseStr));
    dispatch(netContractualEnergyShortfallAction(selectedLebel, databaseStr));

    if (projectDetail?.plantDataAvailability?.netPVsyst) {
      dispatch(pvsystGHINormalisedAction({}, databaseStr));
      dispatch(pvsystGIINormalisedAction({}, databaseStr));
    }
    if (projectDetail?.plantDataAvailability?.netContractual) {
      dispatch(pvsystContractualEnergyAction({}, databaseStr));

      dispatch(netNormalisedEnergyShortfallAction({}, databaseStr));
    }
  }, []);

  return (
    <>
      {loading5 ? (
        <GraphLoader />
      ) : (
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          textAlign={"center"}
        >
          {/* ###   Detailed Container 1 Start   ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "0 auto",
              padding: "1vmax",
            }}
            className="detail_overview_container"
          >
            <PvsystActualGHIContainer
              progressValueAnimate={progressValueAnimate}
              setProgressValueAnimate={setProgressValueAnimate}
              GHIGTI={GHIGTI}
              loading={loading}
            />

            <PvsystActualGTIContainer
              progressValueAnimate={progressValueAnimate}
              setProgressValueAnimate={setProgressValueAnimate}
              GHIGTI={GHIGTI}
              loading={loading}
            />
            <PvsystAmbientTemp
              progressValueAnimate={progressValueAnimate}
              setProgressValueAnimate={setProgressValueAnimate}
              pvsystActualTemp={pvsystActualTemp}
              loading={loading2}
            />
          </Grid>

          {/* ###   Detailed Container 1 end ### */}

          {/* ###   Detailed Container 2 start ### */}

          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto 0",
              padding: "1vmax",
            }}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"1vmax"}
            >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                  minHeight: "40vh",
                }}
              >
                <PvsystActualGHI GHIGTI={GHIGTI} loading={loading} />
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                <PvsystActualGTI
                  barColors={[colors3[0], colors3[1]]}
                  GHIGTI={GHIGTI}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* ###   Detailed Container 2 end ### */}

          {/* ###   Detailed Container 3 start ### */}
          <Grid
            sx={{ boxShadow: 2 }}
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              boxSizing: "border-box",
              borderWidth: "3px",
              // borderRadius: "8px",
              margin: "1vmax auto",
              padding: "1vmax",
            }}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"1vmax"}
            >
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                {projectDetail &&
                projectDetail["plantDataAvailability"]["netPVsyst"] ? (
                  <PvsystGHINormalised
                    pvsystNormalised={pvsystGHINormalised}
                    loading={loading6}
                  />
                ) : (
                  <NetNormalisedShortFall
                    netNormalisedEnergyShortfall={netNormalisedEnergyShortfall}
                    loading={loading4}
                  />
                )}
              </Grid>
              <Grid
                item
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  backgroundColor: "#fff",
                  padding: "1vmax",
                  overflow: "auto",
                }}
              >
                {projectDetail &&
                projectDetail["plantDataAvailability"]["netPVsyst"] ? (
                  <PvsystGTINormalised
                    pvsystNormalised={pvsystGIINormalised}
                    loading={loading7}
                  />
                ) : (
                  <PvsystContractual
                    pvsystContractualEnergy={pvsystContractualEnergy}
                    loading={loading3}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* ###   Detailed Container 3 end ### */}

          {/* ###   Detailed Container 4 start ### */}
          {projectDetail &&
            !projectDetail?.["plantDataAvailability"]?.["netPVsyst"] && (
              <Grid
                sx={{ boxShadow: 2 }}
                item
                xl={11.8}
                lg={11.8}
                md={11.8}
                sm={11.8}
                xs={11.8}
                style={{
                  background:
                    "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
                  boxSizing: "border-box",
                  borderWidth: "3px",
                  // borderRadius: "8px",
                  margin: "0 auto 1vmax",
                  padding: "1vmax",
                }}
              >
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"1vmax"}
                >
                  <Grid
                    item
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <PvsystGTINormalised2
                      pvsystNormalised={pvsystContractualEnergy}
                      loading={loading3}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={5.9}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      position: "relative",
                      backgroundColor: "#fff",
                      padding: "1vmax",
                      overflow: "auto",
                    }}
                  >
                    <PvsystNetEnergy
                      pvsystContractualEnergy={pvsystContractualEnergy}
                      loading={loading3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
};

export default Detailed;
