import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CustomizeTootip from "../../../../components/CustomizeTootip";
import { colors1, colors2, colors3 } from "../../../../colors/color";
import CustomizeLegend from "../../../../components/CustomizeLegend";

import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { exportComponentAsJPEG } from "react-component-export-image";
import { saveToSvg } from "../../../../constants/Utils";
import Printer2 from "../../../../components/Printer2";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { inverterEfficiencyAction } from "../../../../actions/energyAction";
import "./inverterEfficiency.css";
import GraphLoader from "../../../../components/GraphLoader";

const InverterEfficiency = ({
  selectedValue,
  selectedLebel,
  scbsmbDetail,
  databaseStr,
}) => {
  const { inverterEfficiency = [], loading } = useSelector(
    (state) => state.inverterEfficiency
  );

  const dispatch = useDispatch();
  const [tooltipAdjust, setTooltipAdjust] = useState({ x: null, y: null });
  const [title, setTitle] = useState(null);

  /* ###   Initialize State to animate Graph Bars and Lines   ### */

  const [showEfficiency, setShowEfficiency] = useState({});
  /* ###   Reference to download graph data in CSV format   ### */

  const downloadRef = useRef(null);

  /* ###   Reference for Graph container TO Download In Jpg and Svg format   ### */

  const graphRef = useRef(null);

  const minMaxRange = useMemo(() => {
    if (inverterEfficiency && inverterEfficiency?.length > 0) {
      let obj = {};
      Object.keys(inverterEfficiency[0]).forEach((e) => {
        if (e !== "name") {
          obj[e] = true;
        }
      });
      obj["all"] = true;
      setShowEfficiency(obj);
      return {
        min: inverterEfficiency?.reduce((acc, curr, index) => {
          Object.keys(curr).forEach((e) => {
            if (e !== "name") {
              acc = Math.min(curr[e], acc);
            }
          });
          return acc;
        }, Infinity),
        max: inverterEfficiency?.reduce((acc, curr, index) => {
          Object.keys(curr).forEach((e) => {
            if (e !== "name") {
              acc = Math.max(curr[e], acc);
            }
          });
          return acc;
        }, -Infinity),
      };
    }
    return {};
  }, [inverterEfficiency]);

  useEffect(() => {
    if (selectedLebel?.endDataValue) {

      dispatch(
        inverterEfficiencyAction(scbsmbDetail, selectedLebel, databaseStr)
      );
    }
  }, [selectedLebel?.endDataValue]);

  useEffect(() => {
      dispatch(inverterEfficiencyAction(scbsmbDetail, {}, databaseStr));
  }, [scbsmbDetail]);

  return (
    <>
      <>
        <Grid
          container
          style={{
            minHeight: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0 0 1vmax 0",
          }}
        >
          <Grid
            item
            xl={11.8}
            lg={11.8}
            md={11.8}
            sm={11.8}
            xs={11.8}
            style={{
              background:
                "linear-gradient(to bottom, rgb(3, 99, 125),rgb(4, 128, 162),rgb(4, 135, 172))",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1vmax",
              padding: "1vmax",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                position: "relative",
                backgroundColor: "white",
                padding: "1vmax",
                overflow: "auto",
              }}
            >
              <CSVLink
                data={
                  JSON.parse(
                    JSON.stringify(inverterEfficiency)?.replaceAll(
                      "name",
                      "Date"
                    )
                  ) || []
                }
                filename="data.csv"
                className="hidden"
                target="_blank"
                ref={downloadRef}
              />
              <div
                style={{
                  padding: "0.5rem 1.5rem",
                  position: "sticky",
                  left: "0",
                  right: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  zIndex: "1",
                }}
              >
                <h3>
                  Inverter Efficiency (
                  {scbsmbDetail.block && scbsmbDetail.inverter
                    ? "BL-" +
                      scbsmbDetail.block?.split(" ")[1] +
                      "-INV-" +
                      (scbsmbDetail.inverter !== "All Inverter"
                        ? scbsmbDetail.inverter?.split(" ")[1]
                        : "All")+(scbsmbDetail?.stack?"-STACK-"+scbsmbDetail.stack?.split(" ")[1]:"")
                    : "All Inverters"}
                  ){" "}
                </h3>
                <Printer2
                  clickhandler={() => downloadRef.current.link.click()}
                  jpgDownload={() => {
                    if (!graphRef.current) return null;
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "block";
                    exportComponentAsJPEG(graphRef, {
                      fileName: "graph",
                    });
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "none";
                  }}
                  svgDownload={async () => {
                    if (!graphRef.current) return null;
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "block";
                    const svgData = await saveToSvg(graphRef.current.container);
                    document.getElementsByClassName(
                      "cpy_right"
                    )[0].style.display = "none";
                    saveAs(svgData, "graph.svg");
                  }}
                />
              </div>

              <div
                style={{
                  minWidth: "100%",
                  width: loading
                    ? "100%"
                    : inverterEfficiency?.length > 0
                    ? inverterEfficiency.length *
                        // Object.keys(inverterEfficiency[0]).length*(window.innerWidth<500 && window.innerWidth>400?8:window.innerWidth<400?10:6)
                        Object.keys(inverterEfficiency[0]).length *
                        35 +
                      "px"
                    : "100%",
                }}
                className="inverter-efficiency-responsive-container"
              >
                <ResponsiveContainer
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "0",
                        left: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <GraphLoader />
                    </div>
                  ) : (
                    <ComposedChart
                      style={{ overflow: "hidden" }}
                      onMouseMove={(e) => {
                        if (e.isTooltipActive) {
                          setTooltipAdjust({
                            x:
                              // e.chartX>e.activeCoordinate.x?e.activeCoordinate.x:
                              e.chartX > 20 ? e.chartX - 20 : e.chartX,
                            y: e.chartY > 150 ? 150 : e.chartY,
                          });
                        }
                      }}
                      barSize={30}
                      // maxBarSize={50}
                      // barGap={5}
                      data={inverterEfficiency || []}
                      margin={{
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5,
                      }}
                      // barCategoryGap={"1%"}
                      ref={graphRef}
                    >
                      <CartesianGrid stroke="grey" strokeWidth={0.3} />
                      <XAxis
                        dataKey="name"
                        fontSize={"0.8rem"}
                        fontWeight={600}
                        tickLine={false}
                        axisLine={false}
                        tickMargin={5}
                      />
                      <YAxis
                        yAxisId="left-axis"
                        fontSize={"0.8rem"}
                        fontWeight={600}
                        domain={[
                          minMaxRange.max == minMaxRange.min
                            ? minMaxRange.min - 1
                            : minMaxRange.min -
                              (minMaxRange.max - minMaxRange.min) / 10,
                          minMaxRange.min == minMaxRange.max
                            ? minMaxRange.max + 1
                            : minMaxRange.max +
                              (minMaxRange.max - minMaxRange.min) / 10,
                        ]}
                        tickLine={false}
                        tickMargin={8}
                        // interval="preserveStartEnd"
                        tickFormatter={(tick) =>
                          Math.abs(minMaxRange.max - minMaxRange.min) <= 5
                            ? tick.toFixed(2)
                            : Math.floor(tick)
                        }
                      />
                      <Tooltip
                        position={tooltipAdjust}
                        content={
                          <CustomizeTootip
                            active={false}
                            payload={[]}
                            label={""}
                            TooltipValues={
                              inverterEfficiency?.length > 0
                                ? Object.keys(inverterEfficiency[0]).filter(
                                    (e) => e !== "name"
                                  )
                                : []
                            }
                            showDataKey={true}
                          />
                        }
                      />
                      <Legend
                        content={
                          <CustomizeLegend
                            active={false}
                            payload={[]}
                            LegendValues={
                              inverterEfficiency?.length > 0
                                ? Object.keys(inverterEfficiency[0]).filter(
                                    (e) => e != "name"
                                  )
                                : []
                            }
                            data={showEfficiency}
                            setData={setShowEfficiency}
                            styles={{
                              fontSize: "1rem",
                            }}
                          />
                        }
                      />
                      {inverterEfficiency?.length > 0 &&
                        Object.keys(inverterEfficiency[0]).map((e, index) => {
                          if (e === "name") return null;
                          return (
                            <Bar
                              key={index}
                              hide={showEfficiency[e] ? false : true}
                              dataKey={e}
                              fill={colors3[0]}
                              yAxisId="left-axis"
                            >
                              {
                                <LabelList
                                  dataKey={e}
                                  position="top"
                                  fontSize={"0.7rem"}
                                  fontWeight={600}
                                />
                              }
                            </Bar>
                          );
                        })}
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};
export default InverterEfficiency;
