import {
  acLineLoss_actualPr_fail,
  acLineLoss_actualPr_request,
  acLineLoss_actualPr_reset,
  acLineLoss_actualPr_sucess,
  all_inverter_efficiency_reset,
  asbuilt_document_fail,
  asbuilt_document_request,
  asbuilt_document_reset,
  asbuilt_document_sucess,
  clear_errors,
  download_asbuilt_document_fail,
  download_asbuilt_document_request,
  download_asbuilt_document_reset,
  download_asbuilt_document_sucess,
  download_projectOverview_image_fail,
  download_projectOverview_image_request,
  download_projectOverview_image_reset,
  download_projectOverview_image_sucess,
  GHI_GTI_data_fail,
  GHI_GTI_data_request,
  GHI_GTI_data_reset,
  GHI_GTI_data_sucess,
  inverter_deviation_fail,
  inverter_deviation_request,
  inverter_deviation_reset,
  inverter_deviation_sucess,
  inverter_efficiency_fail,
  inverter_efficiency_request,
  inverter_efficiency_reset,
  inverter_efficiency_sucess,
  net_contractual_shortfall_fail,
  net_contractual_shortfall_request,
  net_contractual_shortfall_reset,
  net_contractual_shortfall_sucess,
  net_normalised_shortfall_fail,
  net_normalised_shortfall_request,
  net_normalised_shortfall_reset,
  net_normalised_shortfall_sucess,
  pvsyst_actual_temp_fail,
  pvsyst_actual_temp_request,
  pvsyst_actual_temp_reset,
  pvsyst_actual_temp_sucess,
  pvsyst_contractual_energy_fail,
  pvsyst_contractual_energy_request,
  pvsyst_contractual_energy_reset,
  pvsyst_contractual_energy_sucess,
  pvsyst_GHI_normalised_fail,
  pvsyst_GHI_normalised_request,
  pvsyst_GHI_normalised_reset,
  pvsyst_GHI_normalised_sucess,
  pvsyst_GII_normalised_fail,
  pvsyst_GII_normalised_request,
  pvsyst_GII_normalised_reset,
  pvsyst_GII_normalised_sucess,
  pvsyst_normalised_fail,
  pvsyst_normalised_request,
  pvsyst_normalised_reset,
  pvsyst_normalised_sucess,
  scbsmb_monthly_loss_fail,
  scbsmb_monthly_loss_request,
  scbsmb_monthly_loss_reset,
  scbsmb_monthly_loss_sucess,
} from "../constants/dataConstants";

export const scbsmbLossReducer = (
  state = { scbsmbLossMonthly: null, scbsmbLoss:null },
  action
) => {
  switch (action.type) {
    case scbsmb_monthly_loss_request:
      return {
        ...state,
        loading: true,
      };
    case scbsmb_monthly_loss_sucess:
      return {
        ...state,
        loading: false,
        scbsmbLoss: action.payload,
      };
    case scbsmb_monthly_loss_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case scbsmb_monthly_loss_reset:
        return{
            ...state,
        scbsmbLoss: null,

        }
    default:
      return state;
  }
};


export const inverterEfficiencyReducer = (
  state = { inverterEfficiency: null },
  action
) => {
  switch (action.type) {
    case inverter_efficiency_request:
      return {
        ...state,
        loading: true,
      };
    case inverter_efficiency_sucess:
      return {
        ...state,
        loading: false,
        inverterEfficiency: action.payload,
      };
    case inverter_efficiency_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case inverter_efficiency_reset:
        return {
          ...state,
          inverterEfficiency: null,
        };
    default:
      return state;
  }
};

export const inverterDeviationReducer = (
  state = { inverterDeviation: null },
  action
) => {
  switch (action.type) {
    case inverter_deviation_request:
      return {
        ...state,
        loading: true,
      };
    case inverter_deviation_sucess:
      return {
        ...state,
        loading: false,
        inverterDeviation: action.payload,
      };
    case inverter_deviation_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case inverter_deviation_reset:
        return {
          ...state,
          inverterDeviation: null,
        };
    default:
      return state;
  }
};

export const GHIGTIReducer = (
  state = { GHIGTI: null },
  action
) => {
  switch (action.type) {
    case GHI_GTI_data_request:
      return {
        ...state,
        loading: true,
      };
    case GHI_GTI_data_sucess:
      return {
        ...state,
        loading: false,
        GHIGTI: action.payload,
      };
    case GHI_GTI_data_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case GHI_GTI_data_reset:
        return {
          ...state,
          GHIGTI:null,
        };
    default:
      return state;
  }
};

export const pvsystActualTempReducer = (
  state = { pvsystActualTemp: null },
  action
) => {
  switch (action.type) {
    case pvsyst_actual_temp_request:
      return {
        ...state,
        loading2: true,
      };
    case pvsyst_actual_temp_sucess:
      return {
        ...state,
        loading2: false,
        pvsystActualTemp: action.payload,
      };
    case pvsyst_actual_temp_fail:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      }
      case pvsyst_actual_temp_reset:
        return {
          ...state,
          pvsystActualTemp: null,
        };
    default:
      return state;
  }
};

export const pvsystContractualEnergyReducer = (
  state = { pvsystContractualEnergy: null },
  action
) => {
  switch (action.type) {
    case pvsyst_contractual_energy_request:
      return {
        ...state,
        loading3: true,
      };
    case pvsyst_contractual_energy_sucess:
      return {
        ...state,
        loading3: false,
        pvsystContractualEnergy: action.payload,
      };
    case pvsyst_contractual_energy_fail:
      return {
        ...state,
        loading3: false,
        error: action.payload,
      }

      case pvsyst_contractual_energy_reset:
        return {
          ...state,
          pvsystContractualEnergy: null
        };
    default:
      return state;
  }
};


export const netNormalisedEnergyShortfallReducer = (
  state = { netNormalisedEnergyShortfall: null },
  action
) => {
  switch (action.type) {
    case net_normalised_shortfall_request:
      return {
        ...state,
        loading4: true,
      };
    case net_normalised_shortfall_sucess:
      return {
        ...state,
        loading4: false,
        netNormalisedEnergyShortfall: action.payload,
      };
    case net_normalised_shortfall_fail:
      return {
        ...state,
        loading4: false,
        error: action.payload,
      }
      case net_normalised_shortfall_reset:
        return {
          ...state,
          netNormalisedEnergyShortfall: null,
        };
    default:
      return state;
  }
};


export const netContractualEnergyShortfallReducer = (
  state = { netContractualEnergyShortfall: null },
  action
) => {
  switch (action.type) {
    case net_contractual_shortfall_request:
      return {
        ...state,
        loading: true,
      };
    case net_contractual_shortfall_sucess:
      return {
        ...state,
        loading: false,
        netContractualEnergyShortfall: action.payload,
      };
    case net_contractual_shortfall_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case net_contractual_shortfall_reset:
        return {
          ...state,
          loading: false,
          netContractualEnergyShortfall:null,
        };
        case clear_errors:
          return{
            ...state,
            error:null
          }
    default:
      return state;
  }
};


export const acLineLossActualPrReducer = (
  state = { acLineLossActualPr: null },
  action
) => {
  switch (action.type) {
    case acLineLoss_actualPr_request:
      return {
        ...state,
        loading2: true,
      };
    case acLineLoss_actualPr_sucess:
      return {
        ...state,
        loading2: false,
        acLineLossActualPr: action.payload,
      };
    case acLineLoss_actualPr_fail:
      return {
        ...state,
        loading2: false,
        error: action.payload,

      }
      case acLineLoss_actualPr_reset:
        return {
          ...state,
          acLineLossActualPr: null,

        }
    default:
      return state;
  }
};

export const pvsystNormalisedReducer = (
  state = { pvsystGHINormalised: null,pvsystGIINormalised: null },
  action
) => {
  switch (action.type) {
    case pvsyst_GHI_normalised_request:
      return {
        ...state,
        loading3: true,
      };
      case pvsyst_GII_normalised_request:
        return {
          ...state,
          loading4: true,
        };
    case pvsyst_GHI_normalised_sucess:
      return {
        ...state,
        loading3: false,
        pvsystGHINormalised: action.payload,
      };

      case pvsyst_GII_normalised_sucess:
        return {
          ...state,
          loading4: false,
          pvsystGIINormalised: action.payload,
        };
    case pvsyst_GHI_normalised_fail:
      return {
        ...state,
        loading3: false,
        pvsystGHINormalised:null,
        error: action.payload,

      }

      case pvsyst_GII_normalised_fail:
        return {
          ...state,
          loading4: false,
          pvsystGIINormalised:null,
          error2: action.payload,
  
        }
      case pvsyst_GHI_normalised_reset:
        return {
          ...state,
          pvsystGHINormalised: null,

        }

        case pvsyst_GII_normalised_reset:
          return {
            ...state,
            pvsystGIINormalised: null,
  
          }
    default:
      return state;
  }
};

export const asbuiltDocumentReducer = (
  state = { asbuiltDocument: null,downloadAsbuiltDocument:null },
  action
) => {
  switch (action.type) {
    case asbuilt_document_request:
      return {
        ...state,
        loading: true,
      };
    case download_asbuilt_document_request:{
      return{
        ...state,
        loading2:true
      }
    }
    case asbuilt_document_sucess:
      return {
        ...state,
        loading: false,
        asbuiltDocument: action.payload,
      };
      case download_asbuilt_document_sucess:
        return{
        ...state,
        loading2:false,
        downloadAsbuiltDocument:action.payload
      }
    case asbuilt_document_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case download_asbuilt_document_fail:
        return{
        ...state,
        loading2:false,
        error: action.payload,
      }
    case asbuilt_document_reset:
      return {
        ...state,
        asbuiltDocument: null,
      };
      case download_asbuilt_document_reset:
        return{
        ...state,
        loading2:false,
        downloadAsbuiltDocument:null
      }
      case clear_errors:
        return{
          ...state,
          error:null
        }
    default:
      return state;
  }
};


export const projectImagesReducer = (
  state = { projectOverviewImg: null,img2:null },
  action
) => {
  switch (action.type) {
    case download_projectOverview_image_request:
      return {
        ...state,
        loading: true,
      };
    case download_projectOverview_image_sucess:{
      return{
        ...state,
        loading: false,
        projectOverviewImg: action.payload,
      }
    }
    case download_projectOverview_image_fail:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case download_projectOverview_image_reset:{
        return{
          ...state,
          projectOverviewImg: null,
        }
      }
      
      case clear_errors:
        return{
          ...state,
          error:null
        }
    default:
      return state;
  }
};

