/* #####  Constant data values for actions and reducers  ##### */

export const inverter_efficiency_request = "inverter_efficiency_request";
export const inverter_efficiency_sucess = "inverter_efficiency_sucess";
export const inverter_efficiency_fail = "inverter_efficiency_fail";
export const inverter_efficiency_reset = "inverter_efficiency_reset";

export const inverter_deviation_request = "inverter_deviation_request";
export const inverter_deviation_sucess = "inverter_deviation_sucess";
export const inverter_deviation_fail = "inverter_deviation_fail";
export const inverter_deviation_reset = "inverter_deviation_reset";

export const all_inverter_efficiency_request =
  "all_inverter_efficiency_request";
export const all_inverter_efficiency_sucess = "all_inverter_efficiency_sucess";
export const all_inverter_efficiency_fail = "all_inverter_efficiency_fail";
export const all_inverter_efficiency_reset = "all_inverter_efficiency_reset";

export const all_scbsmb_request = "all_scbsmb_request";
export const all_scbsmb_sucess = "all_scbsmb_sucess";
export const all_scbsmb_fail = "all_scbsmb_fail";
export const all_scbsmb_reset = "all_scbsmb_reset";

export const energy_monthly_request = "net_energy_monthly_request";
export const energy_monthly_sucess = "net_energy_monthly_sucess";
export const energy_monthly_fail = "net_energy_monthly_fail";

export const powerplant_details_request = "net_powerplant_details_request";
export const powerplant_details_sucess = "net_powerplant_details_sucess";
export const powerplant_details_fail = "net_powerplant_details_fail";

export const inverter_smb_yearly_loss_request =
  "inverter_smb_yearly_loss_request";
export const inverter_smb_yearly_loss_sucess =
  "inverter_smb_yearly_loss_sucess";
export const inverter_smb_yearly_loss_fail = "inverter_smb_yearly_loss_fail";
export const inverter_smb_yearly_loss_reset = "inverter_smb_yearly_loss_reset";

export const inverter_smb_monthly_loss_request =
  "inverter_smb_monthly_loss_request";
export const inverter_smb_monthly_loss_sucess =
  "inverter_smb_monthly_loss_sucess";
export const inverter_smb_monthly_loss_fail = "inverter_smb_monthly_loss_fail";
export const inverter_smb_monthly_loss_reset =
  "inverter_smb_monthly_loss_reset";

export const heatmap_data_request = "heatmap_data_request";
export const heatmap_data_sucess = "heatmap_data_sucess";
export const heatmap_data_fail = "heatmap_data_fail";
export const heatmap_data_reset = "heatmap_data_reset";

export const user_signup_request = "user_signup_request";
export const user_signup_sucess = "user_signup_sucess";
export const user_signup_fail = "user_signup_fail";
export const user_signup_reset = "user_signup_reset";

export const user_login_request = "user_login_request";
export const user_login_sucess = "user_login_sucess";
export const user_login_fail = "user_login_fail";
export const user_login_reset = "user_login_reset";

export const user_logout_request = "user_logout_request";
export const user_logout_sucess = "user_logout_sucess";
export const user_logout_fail = "user_logout_fail";
export const user_logout_reset = "user_login_reset";

export const get_user_request = "get_user_request";
export const get_user_sucess = "get_user_sucess";
export const get_user_fail = "get_user_fail";
export const get_user_reset = "get_user_reset";

export const load_user_request = "load_user_request";
export const load_user_sucess = "load_user_sucess";
export const load_user_fail = "load_user_fail";
export const load_user_reset = "load_user_reset";
export const user_session_expired = "user_session_expired";

export const registerd_user_request = "registerd_user_request";
export const registerd_user_sucess = "registerd_user_sucess";
export const registerd_user_fail = "registerd_user_fail";
export const registerd_user_reset = "registerd_user_reset";

export const allow_user_request = "allow_user_request";
export const allow_user_sucess = "allow_user_sucess";
export const allow_user_fail = "allow_user_fail";
export const allow_user_reset = "allow_user_reset";

export const delete_user_request = "delete_user_request";
export const delete_user_sucess = "delete_user_sucess";
export const delete_user_fail = "delete_user_fail";
export const delete_user_reset = "delete_user_reset";

export const send_otp_request = "send_otp_request";
export const send_otp_sucess = "send_otp_sucess";
export const send_otp_fail = "send_otp_fail";
export const send_otp_reset = "send_otp_reset";

export const verify_otp_request = "verify_otp_request";
export const verify_otp_sucess = "verify_otp_sucess";
export const verify_otp_fail = "verify_otp_fail";
export const verify_otp_reset = "verify_otp_reset";

export const resend_otp_request = "resend_otp_request";
export const resend_otp_sucess = "resend_otp_sucess";
export const resend_otp_fail = "resend_otp_fail";
export const resend_otp_reset = "resend_otp_reset";

export const project_detail_request = "project_detail_request";
export const project_detail_sucess = "project_detail_sucess";
export const project_detail_fail = "project_detail_fail";
export const project_detail_reset = "project_detail_reset";

export const plantLocation_detail_request = "plantLocation_detail_request";
export const plantLocation_detail_sucess = "plantLocation_detail_sucess";
export const plantLocation_detail_fail = "plantLocation_detail_fail";
export const plantLocation_detail_reset = "plantLocation_detail_reset";

export const scbsmb_yearly_loss_request = "scbsmb_yearly_loss_request";
export const scbsmb_yearly_loss_sucess = "scbsmb_yearly_loss_sucess";
export const scbsmb_yearly_loss_fail = "scbsmb_yearly_loss_fail";
export const scbsmb_yearly_loss_reset = "scbsmb_yearly_loss_reset";

export const scbsmb_monthly_loss_request = "scbsmb_monthly_loss_request";
export const scbsmb_monthly_loss_sucess = "scbsmb_monthly_loss_sucess";
export const scbsmb_monthly_loss_fail = "scbsmb_monthly_loss_fail";
export const scbsmb_monthly_loss_reset = "scbsmb_monthly_loss_reset";

export const GHI_GTI_data_request = "GHI_GTI_data_request";
export const GHI_GTI_data_sucess = "GHI_GTI_data_sucess";
export const GHI_GTI_data_fail = "GHI_GTI_data_fail";
export const GHI_GTI_data_reset = "GHI_GTI_data_reset";

export const pvsyst_actual_temp_request = "pvsyst_actual_temp_request";
export const pvsyst_actual_temp_sucess = "pvsyst_actual_temp_sucess";
export const pvsyst_actual_temp_fail = "pvsyst_actual_temp_fail";
export const pvsyst_actual_temp_reset = "pvsyst_actual_temp_reset";

export const pvsyst_contractual_energy_request =
  "pvsyst_contractual_energy_request";
export const pvsyst_contractual_energy_sucess =
  "pvsyst_contractual_energy_sucess";
export const pvsyst_contractual_energy_fail = "pvsyst_contractual_energy_fail";
export const pvsyst_contractual_energy_reset =
  "pvsyst_contractual_energy_reset";

export const net_normalised_shortfall_request =
  "net_normalised_shortfall_request";
export const net_normalised_shortfall_sucess =
  "net_normalised_shortfall_sucess";
export const net_normalised_shortfall_fail = "net_normalised_shortfall_fail";
export const net_normalised_shortfall_reset = "net_normalised_shortfall_reset";

export const net_contractual_shortfall_request =
  "net_contractual_shortfall_request";
export const net_contractual_shortfall_sucess =
  "net_contractual_shortfall_sucess";
export const net_contractual_shortfall_fail = "net_contractual_shortfall_fail";
export const net_contractual_shortfall_reset =
  "net_contractual_shortfall_reset";

export const acLineLoss_actualPr_request = "acLineLoss_actualPr_request";
export const acLineLoss_actualPr_sucess = "acLineLoss_actualPr_sucess";
export const acLineLoss_actualPr_fail = "acLineLoss_actualPr_fail";
export const acLineLoss_actualPr_reset = "acLineLoss_actualPr_reset";

export const asbuilt_document_request = "asbuilt_document_request";
export const asbuilt_document_sucess = "asbuilt_document_sucess";
export const asbuilt_document_fail = "asbuilt_document_fail";
export const asbuilt_document_reset = "asbuilt_document_reset";

export const download_asbuilt_document_request =
  "download_asbuilt_document_request";
export const download_asbuilt_document_sucess =
  "download_asbuilt_document_sucess";
export const download_asbuilt_document_fail = "download_asbuilt_document_fail";
export const download_asbuilt_document_reset =
  "download_asbuilt_document_reset";

export const download_projectOverview_image_request =
  "download_projectOverview_image_request";
export const download_projectOverview_image_sucess =
  "download_projectOverview_image_sucess";
export const download_projectOverview_image_fail =
  "download_projectOverview_image_fail";
export const download_projectOverview_image_reset =
  "download_projectOverview_image_reset";

export const pvsyst_normalised_request = "pvsyst_normalised_request";
export const pvsyst_normalised_sucess = "pvsyst_normalised_sucess";
export const pvsyst_normalised_fail = "pvsyst_normalised_fail";
export const pvsyst_normalised_reset = "pvsyst_normalised_reset";

export const pvsyst_GHI_normalised_request = "pvsyst_GHI_normalised_request";
export const pvsyst_GHI_normalised_sucess = "pvsyst_GHI_normalised_sucess";
export const pvsyst_GHI_normalised_fail = "pvsyst_GHI_normalised_fail";
export const pvsyst_GHI_normalised_reset = "pvsyst_GHI_normalised_reset";

export const pvsyst_GII_normalised_request = "pvsyst_GII_normalised_request";
export const pvsyst_GII_normalised_sucess = "pvsyst_GII_normalised_sucess";
export const pvsyst_GII_normalised_fail = "pvsyst_GII_normalised_fail";
export const pvsyst_GII_normalised_reset = "pvsyst_GII_normalised_reset";

export const inverter_mappingData_request = "inverter_mappingData_request";
export const inverter_mappingData_sucess = "inverter_mappingData_sucess";
export const inverter_mappingData_fail = "inverter_mappingData_fail";
export const inverter_mappingData_reset = "inverter_mappingData_reset";

export const scbSmb_mappingData_request = "scbSmb_mappingData_request";
export const scbSmb_mappingData_sucess = "scbSmb_mappingData_sucess";
export const scbSmb_mappingData_fail = "scbSmb_mappingData_fail";
export const scbSmb_mappingData_reset = "scbSmb_mappingData_reset";

export const inverter_specification_request = "inverter_specification_request";
export const inverter_specification_sucess = "inverter_specification_sucess";
export const inverter_specification_fail = "inverter_specification_fail";
export const inverter_specification_reset = "inverter_specification_reset";

export const scbSmb_currentDetail_request = "scbSmb_currentDetail_request";
export const scbSmb_currentDetail_sucess = "scbSmb_currentDetail_sucess";
export const scbSmb_currentDetail_fail = "scbSmb_currentDetail_fail";
export const scbSmb_currentDetail_reset = "scbSmb_currentDetail_reset";

export const redirect_user_to_homepage = "redirect_user_to_homepage";


export const new_project_detail_request = "new_project_detail_request";



export const clear_errors = "clear_errors";
