import React from "react";
import "./GenOverView.css";
import { Grid } from "@mui/material";

import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizeTootip from "../../../../components/CustomizeTootip";

import GraphLoader from "../../../../components/GraphLoader";

const ACLineLoss = ({ loading, acLineLossActualPr }) => {
  console.log(acLineLossActualPr)
  return loading ? (
    <Grid
      item
      lg={3.1}
      md={5.9}
      sm={5.9}
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
      }}
    >
      <GraphLoader />
    </Grid>
  ) : !acLineLossActualPr ||
    (
      acLineLossActualPr?.reduce((acc, curr, index) => {
        if (curr["name"] && curr["acLineLoss"]) {
          acc.push({
            name: curr["name"],
            ac_loss: curr["acLineLoss"],
          });
        }

        return acc;
      }, []) || []
    )?.length == 0 ? (
    <Grid
      item
      lg={3.1}
      md={5.9}
      sm={5.9}
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
      }}
    >
      <div style={{ width: "100%", fontSize: "2vmin" }}>
        <p>Ac Line Loss</p>
        <p>Data Not Available</p>
      </div>
    </Grid>
  ) : (
    <>
      <Grid item lg={3.1} md={5.9} sm={5.9} xs={12} className="gen-overview-3">
        <span>AC Line Loss (%)</span>
        <ResponsiveContainer height="90%" width="90%">
          <ComposedChart
            title="Ac Line Loss Percentage Monthly"
            margin={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 5,
            }}
            data={
              acLineLossActualPr?.reduce((acc, curr, index) => {
                if (curr["name"] && curr["acLineLoss"]) {
                  acc.push({
                    name: curr["name"],
                    ac_loss: curr["acLineLoss"],
                  });
                }

                return acc;
              }, []) || []
            }
          >
            <XAxis
              axisLine={false}
              dataKey="name"
              // interval={0}
              fontSize={"0.6rem"}
              tickMargin={8}
              fontWeight={600}
              tickLine={false}
              angle={false}
              padding={{ right: 5 }}
            />
            <YAxis
              hide
              domain={[
                Math.floor(
                  acLineLossActualPr?.reduce(
                    (acc, curr, index) => Math.min(curr["acLineLoss"], acc),
                    Infinity
                  )
                ),
                Math.ceil(
                  acLineLossActualPr?.reduce(
                    (acc, curr, index) => Math.max(curr["acLineLoss"], acc),
                    -Infinity
                  )
                ),
              ]}
            />
            <Tooltip
              cursor={{ fill: "none" }}
              content={
                <CustomizeTootip
                  active={false}
                  payload={[]}
                  label={""}
                  TooltipValues={["Ac Loss"]}
                />
              }
            />
            <Bar
              barSize={12}
              radius={18}
              dataKey="ac_loss"
              fill="rgb(61, 74, 138)"
            ></Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </Grid>{" "}
    </>
  );
};

export default ACLineLoss;
